.leftDiv{
    height: auto;
    border-radius: 10px;
   background-image:linear-gradient(10deg, rgba(255, 232, 236)0%, rgb(247, 251, 174) 208%);;
 
}


.buttonLogo{
   top:20px;
    left:10px ;
    cursor:pointer ;
  
}
.ColorText{
   color: rgb(71, 71, 71);

}
@media (max-width: 500px) {
    .leftDiv{
       height: auto;
    
    }
  
  }