.mainCont{
  width: 70vw;
}
@media (max-width: 500px) {
  .mainCont{
    width: 95vw;
    padding: 0;
   
  }
}




