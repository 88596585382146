.mySwiper {
  width:auto;
   object-fit: cover;
  border-radius: 20px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  object-fit: fill;
}
.cont-bor {
  border-top: 1px solid rgb(169, 162, 162);
}
.order-cont {
  border-top: 1px solid rgb(169, 162, 162);
  height: 70vh;
  justify-content: center;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}



