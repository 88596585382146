.swiper {
  height: auto;
}

.swiper-slide {
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card {
  border: none;
}

.card-body {
  width: 100%;
  justify-content: space-around;
  align-self: center;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  content: "next";
  font-size: medium;
  color: black;
  font-weight: bold;
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-prev:after {
  content: "prev";
  font-size: medium;
  color: black;
  font-weight: bold;
}
.swiper-button-prev:hover,
.swiper-rtl .swiper-button-prev:hover,
.swiper-button-next:hover,
.swiper-rtl .swiper-button-prev:hover {
  background-color: rgb(212, 211, 211);
  border-radius: 10px;
}

@media (max-width: 0px) {
  .card-body {
    width: 100%;
    height: auto;
    text-align: left;
    padding: 20px;
  }
  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    /* right: -8px; */
    right: "0";
    left: auto;
    top: 90px;
    border: 1px solid red;
  }
  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    left: 0px;
    right: auto;
    top: 90px;
    border: 1px solid red;
  }
}
