.terms-container,p{
font-size:0.9rem;
letter-spacing: 0.1ch;

}

h2{
    font-size: 1.2rem;
    font-weight: 700;
   
}