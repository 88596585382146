.item {
  color: black;
  text-decoration: none;
  font-family: var(--para-font);
  font-weight: bold;
  padding: 10px;
  font-size: 16px;
}
.item:hover {
  color: rgb(169, 15, 15);
  border-radius: 5px;
  background-color: rgba(227, 226, 226, 0.292);
  transition: color 0.5s ease-in-out, background-color 0.5s ease-in-out;
}


/* For mobile view navbar */
.hamburger-menu {
  display: none;
  margin-top: 8px;
}


@media (max-width: 821px) {
  .hamburger-menu {
    display: block;
    z-index: 5;
  
  }
  .menu-items {
    display: none;
   
  }
 
}

