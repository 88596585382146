.myImg {
  filter: brightness(60%);
  text-align: center;
  height: 461px;

  
}
.playLogo {
  position: relative;
  /* z-index: 5; */
  cursor: pointer;
  bottom: 250px;
  color: white;
  
}
.you-tube-section{
  padding-top: 3rem;

}
@media (max-width: 823px) {
  .myImg {
    height: 410px;
    object-fit: cover;
    padding: 10px;
  }

  .playLogo {
    position: relative;
    cursor: pointer;
    bottom: 14rem;
    color: white;
  }
}
