.navigation{
    color: white;
      text-decoration: none;
      font-size: 14px;
      font-weight: 400;
      font-family:var(--para-font);
}
.mainDiv{
  background-color:rgba(5, 1, 1, 0.922);
  height: auto;
}

.contact-list{
  line-height: 2.5;
}