.form-container {
  border-radius: 3%;
  padding: 30px;
  background-color: white;
  backdrop-filter: blur(20px);
  box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar {
  width: 0px;
}

.btn-grad {
  /* background-image: linear-gradient(
    to right,
    #ff512f 0%,
    #f09819 51%,
    #ff512f 100%
  ); */
  margin: 10px;
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
}

.btn-grad:hover {
  background-position: right center;
  color: #fff;
  text-decoration: none;
}
h6 {
  position: relative;
  font-size: 30px;
  z-index: 1;
  overflow: hidden;
  text-align: center;
}
h6:before,
h6:after {
  position: absolute;
  top: 50%;
  overflow: hidden;
  width: 48%;
  height: 1px;
  content: "\a0";
  background-color: #1717175a;
}
h6:before {
  margin-left: -50%;
  text-align: right;
}
h6:after {
  margin-left: 2%;
}

/* @media (max-width: 768px) {
  .form-container {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 40%;
    padding: 30px;
    background-color: white;
  }
} */

/* 
@media (min-width: 1400px) {
  .form-container {
    /* position: absolute;
    left: 78%;
    transform: translateX(-50%);

    bottom: 15%;
    padding: 30px;
    background-color: white; 
  }
}

@media (max-width: 400px) {
  .form-container {
    /* position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 40%;
    bottom: 9.5%;
    padding: 30px;
    background-color: white; 
  }
} */
