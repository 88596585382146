.backgroundDiv {
  background-color: rgb(253, 203, 2);
  position: relative;
  height: 250px;
  bottom: 0;
  width: 690px;
  right: -136px;
  top: 257px;
  z-index: -0;
  border-radius: 20px 0px 0px 20px;
  display: block;
}

.qrCode {
  width: 30%;
  height: auto;
}
.playImg {
  width: 35%;
  margin-left: -1rem;
}
.getApp {
  padding: 1rem;
}

@media (max-width: 768px) and (min-width: 300px) {
  .mobImage {
    margin: auto;
  }
  .playImg {
    margin: auto;
  }
  .getApp {
    border: 1px;
    border-radius: 2%;
    box-shadow: 1px 1px 5px 3px #eae9e9;
  }
}
