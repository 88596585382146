@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&family=Rubik:wght@400;500&display=swap");
:root {
  --heading-font: "Montserrat", sans-serif;
  --para-font: "Rubik", sans-serif;
  --para-color: #464645dc;
  --primary: black;
  --theme-color:#E50B63;
  --mini-font: 13px;
  --muteWhite: #f8f4f400;
  --white50: #ffffffc5;
}

body {
  overflow-x: hidden;  
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

